.protects-carousel {
  width: auto;
  height: 420px;
  position: relative;
  z-index: 1;
}

.protects-carousel-picture {
  width: 100%;
}

.protects-carousel-content {
  position: absolute;
  z-index: 2;
  top: 0;
  width: auto;
  text-align: center;
  color: $color-white;
  margin: 0 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.protects-carousel-image {
  max-width: 180px;
  height: auto;
  margin-bottom: 50px;
}

.protects-carousel-title {
  @include font-size-and-line-height(28px, 32px);

  margin-bottom: 10px;
  font-family: $protects-font-family;
}

.protects-carousel-description,
.protects-carousel-description p {
  @include font-size-and-line-height(16px, 22px);

  font-family: $protects-font-family;
}

.protects-carousel-wrapper .swiper-pagination-bullet {
  background: transparent;
  border: 1px solid $color-black;

  &-active {
    background: $protects-pagination-bullet-color;
    border: 1px solid $protects-pagination-bullet-color;
  }
}

.protects-carousel-wrapper.carousel-alt .carousel-controls {
  height: 20px;

  @if ($theme-name == "cgr" or $theme-name == "women" or $theme-name == "men") {
    height: 100%;
  }
}

.protects-carousel-cover-img {
  height: 100%;
  min-height: 120px;
  object-fit: cover;
  object-position: center;
  width: 100%;

  @media (min-width: $grid-bp-xs) {
    min-height: 150px;
  }

  @media (min-width: $grid-bp-sm) {
    height: 240px;
  }

  @media (min-width: $grid-bp-md) {
    height: 330px;
  }

  @media (min-width: $grid-bp-lg) {
    height: 368px;
  }
}

@media (min-width: $grid-bp-sm) {
  .protects-carousel {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
  }

  .protects-carousel-content {
    width: auto;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 0 55px;

    &--reverse {
      flex-direction: row-reverse;
    }

    &--no-image {
      text-align: center;
      justify-content: center;
    }
  }

  .protects-carousel-image {
    margin-right: 85px;
    margin-bottom: 0;
  }

  .protects-carousel-content--reverse .protects-carousel-image {
    margin-left: 85px;
    margin-right: 0;
  }
}

@media (min-width: $grid-bp-md) {
  .protects-carousel-image {
    max-width: 235px;
    margin-right: 95px;
  }

  .protects-carousel-content--reverse .protects-carousel-image {
    margin-left: 95px;
  }

  .protects-carousel-content {
    margin: 0 90px;
  }
}

@media (min-width: $grid-bp-lg) {
  .protects-carousel-image {
    max-width: 385px;
    margin-right: 110px;
  }

  .protects-carousel-title {
    @include font-size-and-line-height(40px, 44px);
  }

  .protects-carousel-description,
  .protects-carousel-description p {
    @include font-size-and-line-height(22px, 28px);
  }

  .protects-carousel-content--reverse .protects-carousel-image {
    margin-left: 110px;
  }
}