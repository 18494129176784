.campaign-media-container {
  overflow: hidden;
  position: relative;
  width: auto;
}

.campaign-media-container--is-light {
  @if ($theme-name != "actimove") {
    &:after {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba($color-black, 0.54);
    }
  }
}

.overlay-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  @media (min-width: $grid-bp-sm) {
    margin-top: $campaign-overlay-cta-margin-top;
  }

  @media (min-width: $grid-bp-md) {
    flex-direction: row;
  }
}

.overlay-cta .btn {
  width: fit-content;
  min-width: 130px;

  // First button for campaign media dark theme
  @if ($theme-name == "actimove") {
    @include btn("primary-light");
  } @else if ($theme-name == "leukoplast") {
    @include btn("primary-light");

    padding-right: 44px;
  } @else if ($theme-name == "jobst") {
    @include btn("secondary-light");
  } @else if ($theme-name == "men") {
    @include btn("tertiary");
  } @else {
    @include btn("primary");
  }

  @if ($theme-name == "women" or $theme-name == "com") {
    @include font-size-and-line-height(16px, 22px);
  }
}

.overlay-cta .btn span {
  @if ($theme-name == "men") {
    font-family: $fontfamily-regular;
  }
}

.overlay-cta .btn .icon-button-right {
  @if ($theme-name == "leukoplast") {
    &:before {
      content: $icon-button-right;
    }
  }
}

// Second button for campaign media dark theme
.overlay-cta .campaign-media-container-secondary-btn {
  @if ($theme-name == "cgr" or $theme-name == "women") {
    @include btn("primary-light");
  } @else if ($theme-name == "actimove" or $theme-name == "jobst") {
    @include btn("secondary-light");
  } @else if ($theme-name == "leukoplast") {
    @include btn("primary");
  } @else if ($theme-name == "men") {
    @include btn("primary");

    background-color: $color-white;

    &:hover {
      background-color: $color-white;
    }
  } @else {
    @include btn("secondary");
  }
}

// First button for campaign media light theme
.campaign-media-container--is-light .overlay-cta .btn {
  @if ($theme-name == "actimove" or $theme-name == "leukoplast") {
    @include btn("primary-light");
  } @else if ($theme-name == "jobst") {
    @include btn("secondary-light");
  } @else if ($theme-name == "men") {
    @include btn("tertiary");
  } @else {
    @include btn("primary");
  }
}

// Second button for campaign media light theme
.campaign-media-container--is-light .overlay-cta .campaign-media-container-secondary-btn {
  @if ($theme-name == "men") {
    @include btn("primary");

    background-color: $color-white;

    &:hover {
      background-color: $color-white;
    }
  } @else {
    @include btn("secondary-light");
  }
}

@if ($theme-name == "leukoplast") {
  // This is a padding-bottom hack to maintain aspect ratio
  .campaign-media-container--no-image {
    padding-bottom: 50%;
  }

  .campaign-media-container-secondary-btn {
    @include btn("secondary");
  }

  @media (max-width: $grid-bp-xs) {
    .campaign-media-container--no-image .overlay-container {
      position: static;
      transform: unset;
    }

    .campaign-media-container .overlay-content .btn {
      text-align: center;
    }
  }
}

@include mediaquery(sm) {
  .campaign-media-container video {
    object-fit: cover !important;
  }

  // instead of aspect-ratio 3:2
  .campaign-media-container .jw-aspect {
    padding-top: 66.67% !important;
  }
}

@include mediaquery(xs) {
  .campaign-media-container .jw-aspect {
    padding-top: 100% !important;
  }
}

.campaign-media-container.image-container-fluid {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;

  img {
    width: 100%;
    height: inherit;
  }

  @media (min-width: $grid-bp-md) {
    min-height: 300px;
  }
}

.campaign-media-container img {
  width: 100%;

  @media (max-width: $grid-bp-sm-max) {
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }

  @media (max-width: $grid-bp-xs-max) {
    aspect-ratio: 1 / 1;
  }
}

.overlay-container {
  @include svg-play-icon();

  color: $home-color;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;

  @if ($theme-name == "men") {
    text-align: left;
    width: 100%;

    .overlay-container h1 {
      font-family: $fontfamily-titles-thin;
    }

    .overlay-content {
      max-width: 720px;
    }
  }

  @if ($theme-name != "men") {
    .campaign-header {
      width: auto;
    }
  }

  @if ($theme-name != "leukoplast" and $theme-name != "men") {
    width: 335px;

    @media (min-width: $grid-bp-sm) {
      width: 465px;
    }

    @media (min-width: $grid-bp-md) {
      width: 615px;
    }

    @media (min-width: $grid-bp-lg) {
      width: 772px;
    }
  }
}

@if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
  .overlay-container .svg-icon.play-icon {
    left: 0;
    margin-top: 24px;
    position: relative;
    top: 0;
    transform: unset;
  }
}

.overlay-container p {
  color: $home-color;
}

.overlay-container .lightenText,
.overlay-container .lightenText p {
  color: $color-white;
}

.overlay-container .campaign-media-subheading {
  @include font-size-and-line-height($font-size-caption-image-subheader, $line-height-campaign-image-subheader);

  font-family: $fontfamily-regular;
}

.overlay-container .campaign-media-heading {
  @include font-size-and-line-height($font-size-caption-image-header, $line-height-caption-image-header);

  font-family: $campaign-media-title-font;
  font-weight: normal;
  margin-top: 0;
}

@if ($theme-name == "men") {
  .overlay-container h4 {
    margin-bottom: 16px;
  }

  .overlay-container h1 {
    margin-bottom: 25px;
  }

  .overlay-container h4,
  .overlay-container h1 {
    text-transform: uppercase;
  }

  .overlay-cta {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.overlay-container .campaign-media-description {
  @include font-size-and-line-height($font-size-caption-image-subheader, $line-height-caption-image-subheader);
}

@if ($theme-name == "cutimed") {
  .campaign-media-container-secondary-btn {
    @include btn("secondary");
  }
}

@if ($theme-name == "actimove") {
  .campaign-media-container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 3px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.54) 100%);
    }
  }

  .campaign-media-container.campaign-media-container--video::before {
    content: none;
  }

  .campaign-media-container .svg-icon.play-icon {
    fill: transparent;
    width: 60px;
    height: 60px;
  }

  .campaign-media-container .play-icon-arrow {
    fill: $color-white;
  }

  .overlay-container .campaign-media-subheading {
    @include font-size-and-line-height($campaign-subtitle-font-size, $campaign-subtitle-line-height);

    font-family: $fontfamily-regular;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }

  .overlay-container .campaign-media-heading {
    @include font-size-and-line-height($campaign-title-font-size, $campaign-title-line-height);

    font-family: $fontfamily-header;
    margin-bottom: 16px;
    font-style: italic;
  }

  .overlay-container .campaign-media-description {
    @include font-size-and-line-height($campaign-description-font-size, $campaign-description-line-height);

    font-family: $fontfamily-regular;
  }

  .campaign-media-container .overlay-container {
    bottom: 0;
    top: unset;
    transform: translate(-50%, -5%);
    width: 90%;
    padding-bottom: 24px;
  }

  .campaign-media-container .overlay-container.overlay-container--video {
    background: none;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    padding: 0;
  }

  .overlay-cta {
    @media (min-width: $grid-bp-md) {
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  @media (min-width: $grid-bp-xs) {
    .campaign-media-container .overlay-container {
      width: 60%;
    }

    .campaign-media-container .overlay-container {
      padding-bottom: 40px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .campaign-media-container {
      background: none;

      &::before {
        content: none;
      }
    }

    .campaign-media-container.image-container-fluid {
      padding-bottom: 25px;
    }

    .campaign-media-container .overlay-container {
      text-align: left;
      padding: 60px 50px 75px 40px;
      background: $campaign-content-background-color;
      position: absolute;
      bottom: 0;
      left: 0;
      top: unset;
      transform: none;
      // Calculates an angle of 10 degrees on the right side
      clip-path: polygon(0 0, 100% 0, calc(100% + -0.18 * 100vmax) 100vmax, 0 100vmax);
      width: 720px;
    }

    .overlay-container.overlay-container--video .svg-icon.play-icon {
      margin-top: 0;
    }
  }
}

@if ($theme-name == "jobst") {
  .overlay-container {
    background-color: $color-primary;
    padding: 24px 15px;
    text-align: left;
    max-width: 100%;
    width: 100%;
    transform: unset;
    position: relative;
    left: 0;
    margin-top: -5px;
  }

  .campaign-header {
    padding: 0;
  }

  .overlay-container .overlay-content {
    padding-bottom: 10px;
  }

  .overlay-cta {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .overlay-cta .btn {
    display: inline-block;
  }

  .overlay-container .campaign-media-subheading {
    color: $color-white;
    font-family: $fontfamily-regular;
    margin-bottom: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .overlay-container .campaign-media-heading {
    font-family: $fontfamily-regular-bold;
    margin-bottom: 16px;
  }

  .overlay-container .campaign-media-description {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
  }

  @media (min-width: $grid-bp-md) {
    .overlay-container {
      background-color: rgba($color-primary, 0.81);
      position: absolute;
      border-bottom-right-radius: 64px;
      padding: 40px;
      text-align: left;
      max-width: 584px;
      bottom: 40px;
      right: 40px;
      top: unset;
      left: unset;
      transform: unset;
    }
  }

  @media (min-width: $grid-bp-lg) {
    .overlay-container {
      right: 135px;
    }
  }
}

@if ($theme-name == "women") {
  .overlay-container .campaign-media-subheading {
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 16px;

    @media (min-width: $grid-bp-md) {
      letter-spacing: 5px;
    }
  }

  .overlay-container .campaign-media-heading {
    margin-bottom: 16px;

    @media (min-width: $grid-bp-md) {
      letter-spacing: -1px;
    }
  }

  .overlay-container .campaign-media-description p {
    margin-bottom: 0;
  }

  .overlay-container .svg-icon.play-icon {
    @media (max-width: $grid-bp-sm-max) {
      margin-top: 16px;
    }
  }
}

@if ($theme-name == "cgr") {
  .overlay-container .campaign-media-subheading {
    letter-spacing: 4px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .overlay-container .campaign-media-heading {
    @include font-size-and-line-height($campaign-heading-font-size, $campaign-media-heading-line-height);

    font-weight: 700;
    letter-spacing: 4px;
    margin-bottom: 16px;
    text-transform: uppercase;

    @media (min-width: $grid-bp-xs) {
      letter-spacing: 5px;
    }
  }
}
