.product-list .component-product-box {
  @if ($theme-name != "leukoplast" and $theme-name != "cutimed" and $theme-name != "jobst") {
    background-color: $product-box-background;
    margin: 0 10px 0;
    border-radius: 8px;
  } @else {
    margin: 0 10px 0 10px;
  }

  @if ($theme-name == "jobst") {
    margin: 0 10px 10px;

    @media (max-width: $grid-bp-xs-max) {
      margin-inline: 5px;
    }
  }
}

.product-list.product-list--single .component-product-box {
  width: calc(50% - 20px);

  @media (min-width: $grid-bp-md) {
    width: calc(25% - 20px);
  }
}

.product-list.product-list--single .swiper-scrollbar {
  display: none;
}

.product-list--single .component-product-box {
  padding: 20px;

  @if ($theme-name == "leukoplast") {
    width: 100%;
  }

  @media (min-width: $grid-bp-md) {
    width: calc(33.33333% - 20px);
    @if ($theme-name == "leukoplast") {
      margin-bottom: 25px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 25px;
    }
  }
}

.product-list .product-box {
  display: flex;
  margin: 0 auto;

  @if ($is-tena-theme) {
    flex-direction: unset;
  } @else {
    flex-direction: column;
  }

  @media (max-width: $grid-bp-xs-max) {
    padding-left: 20px;
    padding-right: 20px;

    @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
      padding: 0;
    }
  }
}

.product-list {
  margin: 0 -10px;
  justify-content: center;

  @if ($theme-name != "leukoplast") {
    justify-content: flex-start;
    @media (min-width: $grid-bp-md) {
      min-height: 400px;
    }
  }
}

.product-list .product-box-main {
  @if ($theme-name == "leukoplast") {
    margin-bottom: $product-box-text-margin-vertical;
    margin-top: $product-box-text-margin-vertical;
  }
}

.product-list .product-box-text p {
  margin: 0;

  @if ($theme-name == "actimove") {
    font-size: $font-size-product-box-text;
  } @else {
    @include font-size($font-size-paragraph);
  }

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    @include line-height($product-paragraph-line-height);

    color: $product-text-color;
  }
}

.product-list .product-box-footer {
  @if ($theme-name == "leukoplast") {
    margin-top: 0;
  }
}

.product-list .component-product-box {
  @media (min-width: $grid-bp-md) {
    width: calc(33.33333% - 20px);

    @if ($theme-name == "leukoplast") {
      margin-bottom: 25px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 25px;
    }

    @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
      margin-bottom: 10px;
    } @else {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .product-list .component-product-box--four-elements {
    width: calc(25% - 20px);
  }

  .product-list .component-product-box--full-width {
    width: 100%;
  }
}

.product-list .component-product-box .title {
  @if ($theme-name == "leukoplast") {
    margin-bottom: 0;
  }
}

.product-list .component-product-box .text {
  @if ($theme-name == "leukoplast") {
    padding-top: 20px;
  }
}

.component-product-box .product-box-img {
  position: relative;
}

.product-box-badge-small {
  color: $color-white;
  position: absolute;
  right: 0;
  min-width: 35px;
  background-color: $product-badge-small-background;
}

.product-box-sample {
  color: $product-badge-small-background;
  border: 1px solid $product-badge-small-background;
  width: auto;
  margin: 0 auto 16px;

  @media (max-width: $grid-bp-xs-max) {
    margin: 0 auto 14px 0;
  }
}

.product-box-badge-small,
.product-box-sample {
  @include font-size-and-line-height($product-sample-font-size, $product-sample-line-height);

  font-family: $fontfamily-regular-bold;
  text-transform: uppercase;
  border-radius: 11px;
  padding: 4px;
}

@if ($theme-name == "cutimed") {
  .product-box-container-image-left {
    margin: 0 -10px;
  }

  .product-box-container-image-left .component-product-box {
    margin: 0 10px;
  }

  .product-list .component-product-box,
  .product-box-container-image-left .component-product-box {
    padding: 45px 0 40px;

    @media (min-width: $grid-bp-xs-max) {
      padding: 37px 37px 27px;
      margin-bottom: 37px;
    }
  }

  .product-list .component-product-box .title,
  .product-box-container-image-left .component-product-box .title {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold-condensed;
  }

  .product-box-container-image-left .product-box-text p {
    margin: 0;
  }
}

@if ($theme-name == "actimove") {
  .product-list .component-product-box {
    padding: 24px;
    border-radius: 0;
  }

  @media (max-width: $grid-bp-xs-max) {
    .product-list .component-product-box {
      padding: 16px;
    }

    .product-list .product-box {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .product-box-badge-small {
    @include font-size-and-line-height(14px, 18px)

    color: $color-white;
    min-width: 55px;
    background-color: $product-badge-small-background;
    padding: 3px 15px 3px 12px;
    clip-path: polygon(0 0, 100% 0, calc(100% + -0.18 * 100vmax) 100vmax, 0 100vmax);
    align-self: flex-start;
    margin-bottom: 10px;
    // negative value to anulate product padding
    margin-top: -16px;
    margin-left: -16px;
    position: unset;
    border-radius: 0;
    text-transform: none;
    font-family: $fontfamily-regular;
    font-weight: 600;

    @media (min-width: $grid-bp-sm) {
      margin-top: -24px;
      margin-left: -24px;
      margin-bottom: 8px;
    }
  }

  .product-box-details {
    @include font-size-and-line-height(14px, 16px);

    font-family: $fontfamily-regular;
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
    margin-bottom: 12px;

    @media (min-width: $grid-bp-sm) {
      justify-content: center;
    }
  }

  .product-box-details-img {
    width: 14px;
    height: 16px;
    margin-right: 5px;
  }
}


@if ($theme-name == "jobst") {
  .product-box-badge-small {
    @include font-size-and-line-height(14px, 18px);

    text-transform: none;
    font-family: $fontfamily-regular;
    color: $color-white;
    background-color: $product-badge-small-background;
    padding: 4px 12px;
    position: absolute;
    left: -24px;
    top: -34px;
    right: unset;
    border-radius: unset;
    border-bottom-right-radius: 10px;
  }

  .product-box-details {
    @include font-size-and-line-height(14px, 18px);

    text-align: center;
    font-family: $fontfamily-regular;
    background-color: $color-light;
    color: $color-gray;
    padding: 4px 12px;
    border-bottom-right-radius: 8px;
    margin: 0 auto 12px;
    width: fit-content;
  }
}